
import { defineComponent, onMounted, onUnmounted, ref, watch } from 'vue';
import { ElMessageBox, ElMessage } from 'element-plus';
import { formatLocalTime } from '@/utils/format-time';

import {
  useChannelAgents,
  useDeleteChannelAgent
} from '@/composables/api';
import {
  DELETED_SUCCESSFULLY_TEXT,
  ResponseError,
  ChannelAgentIdOption,
  DEFAULT_PAGE_SIZE
} from '@/services/api';
import SearchBox from '@/components/keyword-searching/Index.vue';
import permissionUnits from '@/components/permission-units/index.vue';
import { useRoute, useRouter } from 'vue-router';
import { useKeyword, usePage } from '@/composables';

export default defineComponent({
  components: {
    SearchBox,
    permissionUnits
  },
  setup() {
    const { keyword } = useKeyword();
    const { setPageQuery, page, pageSize } = usePage(1, DEFAULT_PAGE_SIZE);
    const route = useRoute();
    const router = useRouter();

    const currentIndex = ref(undefined);
    const { data, isLoading, isFetching, refetch } = useChannelAgents({ page, keyword, pageSize });
    const { isLoading: isDeleting, mutate } = useDeleteChannelAgent();

    const deleteChannelAgent = ({ channelAgentId }: ChannelAgentIdOption, index: Number) => {
      currentIndex.value = index;
      ElMessageBox.confirm('是否確認要刪除？', '警告', {
        confirmButtonText: '刪除',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          mutate(
            { channelAgentId },
            {
              onSuccess() {
                refetch.value();
                ElMessage.success(DELETED_SUCCESSFULLY_TEXT);
              },
              onError(error: ResponseError) {
                ElMessage({
                  message: error.response?.data.message,
                  type: 'error'
                });
              }
            }
          );
        })
        .catch();
    };

    onUnmounted(() => {
      route.query.page = String(page.value);
    });

    return {
      page,
      data,
      isLoading,
      isDeleting,
      isFetching,
      currentIndex,
      keyword,
      setPageQuery,
      formatLocalTime,
      deleteChannelAgent
    };
  }
});
